import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "react-icons-kit"
import { close } from "react-icons-kit/fa/close"

import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Video from "../components/video-tw"

const PVideos = ({ data, location }) => {

  const url = `https://speedcambrasil.com.br${location.pathname}`

  const [overlayOpen, setOverlayOpen] = useState(false)
  const [videoURL, setVideoURL] = useState("")

  const openVideo = url => {
    setVideoURL(url)
    setOverlayOpen(true)
  }

  const closeVideo = () => {
    setOverlayOpen(false)
    setVideoURL("")
  }

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Videos"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <div
        className={`${
          overlayOpen ? "block" : "hidden"
        } h-screen bg-black z-40 w-full fixed`}
      >
        <div className="relative">
          <div className="flex justify-end items-start text-white absolute top-4 right-4 z-50">
            <button onClick={evt => closeVideo()}>
              <Icon size={32} icon={close} />
            </button>
          </div>

          <div id="video-overlay" className=" flex justify-center items-center">
            {videoURL !== "" ? (
              <Video viddeoUrl={videoURL} endAction={closeVideo} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Vídeos</i>
        </h1>
        <div className="h-20" />
        <div className="container mx-auto text-xl m-4">
          <div className="grid grid-cols-12 gap-4 text-left">
            <div className="col-span-1 md:col-span-2" />
            <div className="col-span-10 md:col-span-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="col-span-1">
                  <button
                    onClick={evt =>
                      openVideo("https://www.youtube.com/watch?v=73s7SyFXtEo")
                    }
                  >
                    <div className="relative">
                      <GatsbyImage
                        image={getImage(data.id73s7SyFXtEo)}
                        backgroundColor={false}
                        alt="video speedcam"
                      />
                      <GatsbyImage
                        image={getImage(data.icon)}
                        backgroundColor={false}
                        alt="video speedcam"
                        className="bottom-3 left-3"
                        style={{ position: "absolute" }}
                      />
                    </div>
                  </button>
                  <p className="text-sm mt-2">&nbsp;</p>
                </div>
                <div className="col-span-1">
                  <button
                    onClick={evt =>
                      openVideo(
                        "https://player.vimeo.com/external/576257911.hd.mp4?s=b835c7b21bc5f863bf00b7039b4fbff98a31055a&profile_id=175"
                      )
                    }
                  >
                    <div className="relative">
                      <GatsbyImage
                        image={getImage(data.id576257911)}
                        backgroundColor={false}
                        alt="Alexa Mini"
                      />
                      <GatsbyImage
                        image={getImage(data.icon)}
                        backgroundColor={false}
                        alt="video speedcam"
                        className="bottom-3 left-3"
                        style={{ position: "absolute" }}
                      />
                    </div>
                  </button>
                  <p className="text-sm mt-2">
                    diretor de fotografia: Juliano Lopes
                  </p>
                </div>
                <div className="col-span-1">
                  <button
                    onClick={evt =>
                      openVideo(
                        "http://speedcambrasil.com.br/video-files/brahmosidade.mkv"
                      )
                    }
                  >
                    <div className="relative">
                      <GatsbyImage
                        image={getImage(data.brahmosidade)}
                        backgroundColor={false}
                        alt="Alexa Mini"
                      />
                      <GatsbyImage
                        image={getImage(data.icon)}
                        backgroundColor={false}
                        alt="video speedcam"
                        className="bottom-3 left-3"
                        style={{ position: "absolute" }}
                      />
                    </div>
                  </button>
                  <p className="text-sm mt-2">
                    direção e fotografia: Jr Junior
                  </p>
                </div>
                <div className="col-span-1">
                  <button
                    onClick={evt =>
                      openVideo(
                        "http://speedcambrasil.com.br/video-files/imperia.webm"
                      )
                    }
                  >
                    <div className="relative">
                      <GatsbyImage
                        image={getImage(data.imperio)}
                        backgroundColor={false}
                        alt="Alexa Mini"
                      />
                      <GatsbyImage
                        image={getImage(data.icon)}
                        backgroundColor={false}
                        alt="video speedcam"
                        className="bottom-3 left-3"
                        style={{ position: "absolute" }}
                      />
                    </div>
                  </button>
                  <p className="text-sm mt-2">
                    direção e fotografia: Jr Junior
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="h-20" />
      <Footer />
    </>
  )
}

export default PVideos

export const query = graphql`
  query videosPageData {
    icon: file(relativePath: { eq: "videos/player-button.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 67, height: 49)
      }
    }
    id73s7SyFXtEo: file(relativePath: { eq: "videos/73s7SyFXtEo.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 416
          height: 217
        )
      }
    }
    id576257911: file(relativePath: { eq: "videos/576257911.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 416
          height: 217
        )
      }
    }
    brahmosidade: file(relativePath: { eq: "videos/brahmosidade.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 416
          height: 217
        )
      }
    }
    imperio: file(relativePath: { eq: "videos/imperio.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 416
          height: 217
        )
      }
    }
  }
`
