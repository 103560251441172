import React, { useState, useEffect } from "react"
import ReactPlayer from "react-player"

const Video = props => {
  // console.log(props)
  const viddeoUrl = props.viddeoUrl

  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)
  const [mobile, setMobile] = useState(false)
  const [horizontal, setHorizontal] = useState(false)

  useEffect(() => {
    const changeVideoWindow = () => {
      let calcVideoWidth = 0
      let calcVideoHeight = 0
      if (typeof window !== "undefined") {
        // console.log("window aspect", window.innerWidth / window.innerHeight)
        if (horizontal) {
          calcVideoHeight = window.innerHeight-40-16
          calcVideoWidth = Math.floor(window.innerHeight * 1.77)
        } else {
          switch (true) {
            case window.innerWidth > 1536:
              calcVideoWidth = 1536
              calcVideoHeight = Math.floor(1536 / 1.77)
              break
            case window.innerWidth > 1280 && window.innerWidth < 1536:
              calcVideoWidth = 1280
              calcVideoHeight = Math.floor(1280 / 1.77)
              break
            case window.innerWidth > 1024 && window.innerWidth < 1280:
              calcVideoWidth = 1024
              calcVideoHeight = Math.floor(1024 / 1.77)
              break
            case window.innerWidth > 768 && window.innerWidth < 1024:
              calcVideoWidth = 768
              calcVideoHeight = Math.floor(768 / 1.77)
              break
            case window.innerWidth > 640 && window.innerWidth < 768:
              calcVideoWidth = 640
              calcVideoHeight = Math.floor(640 / 1.77)
              break
            default:
              calcVideoWidth = window.innerWidth
              calcVideoHeight = Math.floor(window.innerWidth / 1.77)
          }
        }
        setVideoWidth(calcVideoWidth)
        setVideoHeight(calcVideoHeight)
      }
    }
    changeVideoWindow()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", changeVideoWindow())
      if (window.innerWidth < 1024) {
        setMobile(true)
      }
      if (window.innerWidth / window.innerHeight >= 1) {
        setHorizontal(true)
      }
    }
  }, [horizontal,mobile])

  // sm: 640px,
  // md: 768px,
  // lg: 1024px,
  // xl: 1280px,
  // 2xl: 1536


  return (
    <>
      <ReactPlayer
        url={viddeoUrl}
        playing={true}
        controls={true}
        width={videoWidth}
        height={videoHeight}
        className={`${horizontal ? `my-0 mx-auto` : `my-0 lg:my-2 mx-auto`}`}
        onEnded={props.endAction}
      />
    </>
  )
}

export default Video
